
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import ContactCard from '../components/ContactCard';
import CategorySpecificCarousel from '../components/SingleProduct/CategorySpecificCarousel';
import { Catalog } from '../utils/types/Product';
import { useApi } from '../services/useApi';
import { CatalogBaseIncludes } from '../utils/constants';
import styles from '../styles/EmptyState.module.less';
const Error404: FC = () => {
    const { t } = useTranslation('common');
    const { getResource } = useApi();
    const { data: categories } = getResource<Catalog>('catalog', `include=${CatalogBaseIncludes.join(',')}&fields[catalog.lists]`);
    return (<div className={`${styles.container} max-width-m`}>
      <div className={`${styles['empty-state']} ${styles['not-found-section']}`}>
        <h2 className={styles.title}>
          {t('Oops! 404, this page not found')}
        </h2>
        <p className={styles.subtitle}>
          {t('Ask about an alternative option by contacting a product specialist')}
        </p>
        <ContactCard hasPhone hasContactModalButton className="contact-card"/>
      </div>
      <CategorySpecificCarousel isFor404Page categories={categories}/>
    </div>);
};
export default Error404;

    async function __Next_Translate__getStaticProps__1961aef44e4__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1961aef44e4__ as getStaticProps }
  